* {
  box-sizing: border-box;
  /* Make sure all elements are above the background */
  z-index: 1;
  position: relative;
}

.toggle-wrapper {
  width: 60px;
  display: block;
  margin: 10px;

  .hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .toggle {
    height: 30px;
    width: 60px;
    background: var(--text-primary);
    border-radius: 40px;
    padding: 12px;
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      height: 18px;
      width: 18px;
      border-radius: 30px;
      background: var(--background-secondary);
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 2;
      transform: translate(0);
      transition: transform 0.5s ease;
    }

    &.enabled::before {
      transform: translateX(30px);
    }

    & input {
      position: absolute;
      top: 0;
      opacity: 0;
    }

    .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      svg {
        color: var(--background-secondary);
        height: 12px;
        width: 12px;
        z-index: 0;
      }
    }
  }
}
