@import "../../../styles/shared";

.projectInfo {
  &__tile {
    width: 70%;
    height: 300px;
    margin: auto;
    margin-bottom: 80px;
    display: flex;

    &--santander {
      background-color: $santander-pastel;
    }

    &--bt {
      background-color: $bt-pastel;
    }

    &--kitman {
      background-color: $kitman-pastel;
    }

    &--thg {
      background-color: $thg-pastel;
    }
  }

  &__tile-image {
    margin: auto;
    transition: ease-in-out 0.4s;

    &--santander {
      height: 80px;
    }

    &--bt {
      height: 100px;
    }

    &--kitman {
      height: 30px;
    }

    &--thg {
      height: 80px;
    }
  }

  &__tech-container {
    width: 95%;
    margin: 60px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__tech-icon {
    font-size: 4em;
    text-align: center;
    color: $primary-dark;
  }

  &__line {
    width: 50%;
    padding-top: 50px;
    margin: auto;
    border-bottom: 4px solid $primary-light;
  }
}

@media (min-width: $tablet-wide) {
  .projectInfo {
    &__tile {
      width: 40%;
    }

    &__tile-image {
      &--santander {
        height: 100px;
      }

      &--bt {
        height: 120px;
      }

      &--kitman {
        height: 50px;
      }
    }

    &__tech-container {
      width: 40%;
    }

    &__line {
      width: 300px;
    }
  }
}
