@import "../../styles/shared";

.download-cv {
  text-align: center;
  padding: 100px 30px;
  font-family: $body;

  &__title {
    font-size: $mobile-h2;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__description {
    font-size: $mobile-h3;
    margin-bottom: 50px;
  }

  &__button {
    padding: 15px 60px;
    background-color: $primary-light;
    border-radius: 5px;
    font-family: $body;
    font-size: $notes;
    text-decoration: none;
    color: white;
    transition: 0.5s ease-in;

    &:hover {
      background-color: $primary-dark;
      transition: 0.5s ease-out;
    }
  }
}

@media (min-width: $tablet) {
  .download-cv {
    &__title {
      font-size: $tablet-h2;
    }

    &__description {
      font-size: $tablet-h3;
    }
  }
}

@media (min-width: $desktop) {
  .download-cv {
    &__title {
      font-size: $desktop-h2;
    }

    &__description {
      font-size: $desktop-h3;
    }
  }
}
