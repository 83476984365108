@import "../../../styles/shared";

.intro {
  font-family: $body;
  text-align: center;

  &__title {
    font-size: $mobile-h1;
    line-height: 40px;
    margin: 0px $mobile-gutter;
    padding-bottom: 40px;
    font-weight: bold;
  }

  &__description {
    font-size: $mobile-h4;
    line-height: 30px;
    margin: 0px $mobile-gutter;
    padding-bottom: 70px;
  }
}

@media (min-width: $tablet) {
  .intro {
    &__title {
      line-height: 60px;
      font-size: $tablet-h1;
      margin: 0px $tablet-gutter;
    }

    &__description {
      font-size: $tablet-h4;
      margin: 0px 110px;
    }
  }
}

@media (min-width: $desktop) {
  .intro {
    &__title {
      font-size: $desktop-h1;
      width: 50%;
      margin: auto;
    }

    &__description {
      font-size: $desktop-h4;
      width: 40%;
      margin: auto;
    }
  }
}
