@import "../../styles/shared";

.skills {
  .body {
    margin-top: 60px;
  }

  &__container {
    display: flex;
    margin: auto;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 40px 0px;

    &:after {
      content: "";
      flex: auto;
    }

    &-item {
      font-size: 4em;
      text-align: center;
      color: $primary-dark;
      padding-bottom: 60px;
    }
  }

  &__link {
    display: block;
    margin: 30px 0px 20px;
    font-family: $body;
    font-size: 18px;
    color: var(--text-primary);
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &-a:hover {
      text-decoration: underline;
    }
  }

  &__description {
    font-family: $body;
    font-size: $mobile-p;
    line-height: 20px;
    margin: 0px 40px;
    color: var(--text-secondary);
    transition: ease-out 1s;

    &:hover {
      cursor: pointer;
    }
  }

  &__tech-stack {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px 40px;
    padding: 40px;
    margin: auto;
  }

  &__icon {
    height: 70px;

    &--lrg {
      height: 50px;
    }

    &--lrger {
      height: 60px;
    }

    &--xtra-lrg {
      height: 35px;
    }

    &--white {
      filter: brightness(0) invert(1);
    }
  }

  &__more {
    color: $primary-dark;
    margin-left: 5px;
    height: 20px;
    width: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  &__button {
    background: none;
    border: none;
  }
}

@media (min-width: $mobile-wide) {
  .skills {
    &__container {
      margin: 0px $tablet-gutter;
    }
  }
}

@media (min-width: $tablet-wide) {
  .skills {
    &__container {
      flex-direction: row;
      margin: 0px $tablet-gutter;

      &-item {
        padding-bottom: 10px;
        width: calc(100% / 4);
      }
    }

    &__tech-stack {
      width: 50%;
    }
  }
}
