// Fonts
$primary-light: #90a4ae;
$primary-dark: #62757f;
$body-light: rgba(0, 0, 0, 0.61);

// Sections
$light-grey: #f8f6f5;
$dark: #191b21;
$light-dark: #24272f;

// Clients
$santander-pastel: #ff6961;
$bt-pastel: #b19cd9;
$kitman-pastel: #5e6eff;
$thg-pastel: #d2d2d1;
