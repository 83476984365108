@import "../../styles/shared";

.qualifications {
  img[alt="Teesside Logo"] {
    filter: var(--white-filter);
  }

  .title {
    padding-bottom: 40px;

    &__border {
      display: none;
    }
  }

  &__container {
    display: flex;
    margin: auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &-item {
      font-size: 4em;
      text-align: center;
      color: $primary-dark;
      padding-bottom: 60px;
    }
  }

  &__image {
    opacity: 0.8;
    object-fit: scale-down;
    height: 64px;

    &--light {
      filter: var(--white-filter);
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}

@media (min-width: $mobile-wide) {
  .qualifications {
    &__container {
      margin: 0px $tablet-gutter;
    }
  }
}

@media (min-width: $tablet-wide) {
  .qualifications {
    &__container {
      flex-direction: row;
      margin: 0px $tablet-gutter;

      .skills__container-item {
        width: calc(75% / 4);

        svg {
          padding-top: 5px;
        }
      }
    }
  }
}
