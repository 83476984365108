@import "../../../styles/shared";

.nav {
  width: 100%;
  padding: 20px 0px;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  z-index: 99;
  transition-property: background-color, color;
  transition-duration: 0.5s, 0.5s;
  align-items: center;

  &__logo {
    font-family: $heading-2;
    font-size: 32px;
    margin-left: 100px;
    color: var(--text-primary);
    &--mobile {
      display: none;
    }
  }

  &__list {
    margin-right: 100px;
    align-self: center;
    display: flex;
    align-items: center;

    &-items {
      display: inline;
      padding: 10px;
      font-family: $body;
      font-size: 14px;
      font-weight: bold;

      &-link {
        color: var(--text-primary);
        text-decoration: none;
        border-bottom: 1px solid transparent;
        &:hover {
          border-bottom: 1px solid;
        }
      }
    }
  }

  &__hamburger {
    display: none;
  }
}

@media (max-width: $tablet-wide) {
  .nav {
    flex-direction: row;
    align-items: center;
    padding: 30px 18px;

    &__logo {
      display: none;
      &--mobile {
        display: block;
        font-family: $heading-2;
        font-size: 32px;
        color: var(--text-primary);
      }
    }

    &__list {
      margin: 0px;
      align-self: start;

      &-items {
        display: block;
        padding: 0px;
        &--desktop {
          display: none;
        }
      }
    }

    &__hamburger {
      display: inline-block;
      font-size: 1.4em;
      color: var(--text-primary);
      margin: 0px 5px 0px 10px;
      cursor: pointer;
    }
  }
}
