@import "../../styles/shared";

.projects {
  &__tile-container {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 75%;
    gap: 25px;
  }

  &__tile {
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover > .projects__tile-image {
      transform: scale(1.2);
      cursor: pointer;
    }

    &--santander {
      background-color: $santander-pastel;
    }

    &--bt {
      background-color: $bt-pastel;
    }

    &--kitman {
      background-color: $kitman-pastel;
    }

    &--thg {
      background-color: $thg-pastel;
    }
  }

  &__tile-image {
    transition: ease-in-out 0.4s;

    &--santander {
      height: 60px;
    }

    &--bt {
      height: 60px;
    }

    &--kitman {
      height: 30px;
    }

    &--thg {
      height: 60px;
    }
  }
}

@media (min-width: $tablet-wide) {
  .projects {
    min-height: 1100px;
    &__tile-container {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 25px;
    }

    &__tile {
      width: calc(90% / 2);
      height: 400px;
      flex-grow: 1;
    }

    &__tile-image {
      &--santander {
        height: 100px;
      }

      &--bt {
        height: 90px;
      }

      &--kitman {
        height: 40px;
      }

      &--thg {
        height: 80px;
      }
    }
  }
}
