@import "../../../styles/shared";

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: var(--background-primary);
  transform: translateX(-100%);
  transition: transform 400ms ease-in;

  &__content {
    margin: 25vh 0;
  }

  &__close {
    position: fixed;
    top: 25px;
    right: 25px;
    font-size: 1.5em;
    color: var(--text-secondary);
    cursor: pointer;
  }

  &__logo {
    font-family: $heading-2;
    font-size: 42px;
    text-align: center;
    color: var(--text-primary);
  }

  &__nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 20px 0px;

    &-item {
      font-family: $body;
      font-size: 14px;
      margin: 20px 0px;
      font-weight: bold;
    }

    &-link {
      color: var(--text-primary);
      text-decoration: none;
    }
  }
  &__socials {
    display: flex;
    justify-content: center;
    color: var(--text-primary);

    &-icon {
      margin: 10px;
      font-size: 1.2em;
    }
  }
}

.overlay.slideIn {
  transform: translateX(0);
}

.overlay.slideOut {
  transform: translateX(-100%);
}
