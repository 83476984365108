// Breakpoints
$mobile: 320px;
$mobile-wide: 600px;
$tablet: 768px;
$tablet-wide: 960px;
$desktop: 1200px;

// Gutters
$mobile-gutter: 60px;
$tablet-gutter: 120px;
$desktop-gutter: 580px;

// Fonts
$heading: "Monoton", cursive;
$heading-2: "Kaushan Script", cursive;
$body: "Montserrat", sans-serif;

// Font sizes
// Mobile
$mobile-h1: 32px;
$mobile-h2: 26px;
$mobile-h3: 20px;
$mobile-h4: 18px;
$mobile-p: 16px;
$notes: 14px;

// Tablet
$tablet-h1: 40px;
$tablet-h2: 32px;
$tablet-h3: 22px;
$tablet-h4: 20px;
$tablet-p: 16px;

// Desktop
$desktop-h1: 48px;
$desktop-h2: 36px;
$desktop-h3: 24px;
$desktop-h4: 20px;
$desktop-p: 16px;
